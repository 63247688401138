@import "@/assets/scss/abstracts/_global.scss";

.c-media-footer {
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;
  padding-bottom: 39.21568627450981%;
  position: relative;

  &.is-video {
    cursor: pointer;
  }

  &__play {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
  }

  &__video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
    background-color: #000;

    &:focus {
      outline: none;
    }
  }
}
