.u-flex{
  display:flex
}

.u-inline-flex{
  display:inline-flex
}

.u-h1{
  font-family:Siemens Progress, ui-sans-serif, system-ui, Arial;
  font-size:2.5rem;
  line-height:2.75rem
}

@media (min-width: 768px){
  .u-h1{
    font-size:5rem;
    line-height:5rem
  }
}

.u-h1{
  color:#fff
}

.u-h2{
  font-family:Siemens Progress, ui-sans-serif, system-ui, Arial;
  font-size:1.5rem;
  line-height:1.875rem
}

@media (min-width: 768px){
  .u-h2{
    font-size:1.875rem;
    line-height:2.25rem
  }
}

.u-h2{
  color:#fff
}

@media screen and (max-width: 768px){
  .u-h2{
    font-family:Siemens Progress, ui-sans-serif, system-ui, Arial;
    font-size:1.25rem;
    line-height:1.5rem
  }

  @media (min-width: 768px){
    .u-h2{
      font-size:1.5rem;
      line-height:1.875rem
    }
  }
}

.c-btn{
  display:inline-flex;
  justify-content:center;
  align-items:center;
  padding:8px 24px 8px 24px;
  border:1px solid #3cc8be;
  border-radius:2px;
  text-align:center;
  transition:all .15s ease-in-out;
  cursor:pointer;
  min-width:230px;
  font-weight:700
}

.c-btn:first-letter{
  text-transform:uppercase
}

.c-btn span:first-letter{
  text-transform:uppercase
}

.c-btn:disabled{
  cursor:not-allowed;
  background:#a5e1e1;
  color:#fff;
  border-color:#a5e1e1
}

.c-btn.c-btn--primary{
  background:#3cc8be;
  color:#fff
}

.c-btn.c-btn--primary:hover{
  background:#21a79e;
  color:#fff;
  border-color:#21a79e
}

.c-btn.c-btn--primary:disabled{
  cursor:not-allowed;
  background:#a5e1e1;
  color:#fff;
  border-color:#a5e1e1
}

.c-btn.c-btn--primary:disabled:hover{
  background:#a5e1e1;
  color:#fff;
  border-color:#a5e1e1
}

.c-btn.c-btn--secondary{
  background:transparent;
  color:#3cc8be;
  border-color:#3cc8be
}

.c-btn.c-btn--secondary:hover{
  color:#3cc8be;
  border-color:#3cc8be;
  outline:1px solid #3cc8be
}

.c-btn.c-btn--secondary:disabled{
  background:transparent;
  cursor:not-allowed;
  color:#3cc8be;
  border-color:#3cc8be;
  opacity:.4
}

.c-btn.c-btn--secondary:disabled:hover{
  color:#3cc8be;
  border-color:#3cc8be;
  outline:none
}