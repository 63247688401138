.u-flex{
  display:flex
}

.u-inline-flex{
  display:inline-flex
}

.u-h1{
  font-family:Siemens Progress, ui-sans-serif, system-ui, Arial;
  font-size:2.5rem;
  line-height:2.75rem
}

@media (min-width: 768px){
  .u-h1{
    font-size:5rem;
    line-height:5rem
  }
}

.u-h1{
  color:#fff
}

.u-h2{
  font-family:Siemens Progress, ui-sans-serif, system-ui, Arial;
  font-size:1.5rem;
  line-height:1.875rem
}

@media (min-width: 768px){
  .u-h2{
    font-size:1.875rem;
    line-height:2.25rem
  }
}

.u-h2{
  color:#fff
}

@media screen and (max-width: 768px){
  .u-h2{
    font-family:Siemens Progress, ui-sans-serif, system-ui, Arial;
    font-size:1.25rem;
    line-height:1.5rem
  }

  @media (min-width: 768px){
    .u-h2{
      font-size:1.5rem;
      line-height:1.875rem
    }
  }
}

.c-textmedia{
  display:flex;
  margin-bottom:16px
}

.c-textmedia .c-textmedia--img-right .c-textmedia__img-container{
  order:2
}

.c-textmedia .c-textmedia--img-right .c-textmedia__text-container{
  order:1
}

.c-textmedia .c-textmedia__img-container{
  display:flex;
  flex:2;
  max-width:50%
}

@media screen and (max-width: 768px){
  .c-textmedia .c-textmedia__img-container{
    max-width:100%
  }
}

.c-textmedia .c-textmedia__img{
  width:100%;
  height:100%;
  -o-object-fit:cover;
     object-fit:cover
}

.c-textmedia .c-textmedia__text-container{
  flex:1
}

.c-textmedia .c-textmedia__text{
  padding:0 40px;
  color:#fff
}

.c-textmedia .c-textmedia__text:deep(p),.c-textmedia .c-textmedia__text:deep(ul){
  margin-top:16px;
  margin-bottom:16px
}

.c-textmedia .c-textmedia__text:deep(li){
  padding-left:32px;
  position:relative
}

.c-textmedia .c-textmedia__text:deep(li):not(:last-child){
  margin-bottom:8px
}

.c-textmedia .c-textmedia__text:deep(li)::before{
  content:"";
  height:16px;
  width:16px;
  display:block;
  position:absolute;
  left:0;
  top:50%;
  transform:translateY(-50%);
  background-image:url("data:image/svg+xml,%0A%3Csvg width='16' height='14' viewBox='0 0 16 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M14.3438 0.96875L16 2.625L5.59375 13.0312L0 7.4375L1.65625 5.78125L5.25 8.875L14.3438 0.96875Z' fill='%2341AAAA'/%3E%3C/svg%3E%0A");
  background-repeat:no-repeat
}

.c-textmedia .c-textmedia__heading{
  font-family:Siemens Progress, ui-sans-serif, system-ui, Arial;
  font-size:1.5rem;
  line-height:1.875rem
}

@media (min-width: 768px){
  .c-textmedia .c-textmedia__heading{
    font-size:1.875rem;
    line-height:2.25rem
  }
}

.c-textmedia .c-textmedia__heading{
  font-size:1.675rem;
  line-height:2.1rem;
  margin-bottom:16px
}

@media screen and (max-width: 1024px){
  .c-textmedia .c-textmedia__img-container{
    flex:1
  }
}

@media screen and (max-width: 768px){
  .c-textmedia{
    flex-direction:column
  }

  .c-textmedia .c-textmedia__text{
    padding:20px
  }
}

.c-bundle__textmedia{
  margin-bottom:40px
}