@import "@/assets/scss/abstracts/_global.scss";

.c-basic-feature {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;

  &__text-container {
    height: 100%;
    padding: 20px;
    color: #fff;
  }

  &__heading {
    @include t6;

    font-size: 1.675rem;
    line-height: 2.1rem;
    margin-bottom: 0.5rem;
  }

  &__content {
    //
  }

  &__img-container {
    display: flex;

    .imageslider img {
      height: auto;
    }
  }

  &__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @include mq($screen-m, max) {
    margin-bottom: 20px;
    min-width: 45%;
    width: 100%
  }
  @include mq($screen-s, max) {
    margin-bottom: 20px;
    min-width: 100%;
    width: 100%
  }
}
