.u-flex{
  display:flex
}

.u-inline-flex{
  display:inline-flex
}

.u-h1{
  font-family:Siemens Progress, ui-sans-serif, system-ui, Arial;
  font-size:2.5rem;
  line-height:2.75rem
}

@media (min-width: 768px){
  .u-h1{
    font-size:5rem;
    line-height:5rem
  }
}

.u-h1{
  color:#fff
}

.u-h2{
  font-family:Siemens Progress, ui-sans-serif, system-ui, Arial;
  font-size:1.5rem;
  line-height:1.875rem
}

@media (min-width: 768px){
  .u-h2{
    font-size:1.875rem;
    line-height:2.25rem
  }
}

.u-h2{
  color:#fff
}

@media screen and (max-width: 768px){
  .u-h2{
    font-family:Siemens Progress, ui-sans-serif, system-ui, Arial;
    font-size:1.25rem;
    line-height:1.5rem
  }

  @media (min-width: 768px){
    .u-h2{
      font-size:1.5rem;
      line-height:1.875rem
    }
  }
}

.c-header{
  padding-top:45px;
  padding-bottom:45px;
  display:flex;
  justify-content:space-between
}

@media screen and (max-width: 768px){
  .c-header{
    padding-top:25px;
    padding-bottom:25px
  }
}

.c-header__right{
  display:flex;
  flex-direction:column;
  justify-content:space-between;
  align-items:flex-end
}

@media screen and (max-width: 640px){
  .c-header__right{
    justify-content:center
  }
}

.c-header__language-button{
  font-size:0.875rem;
  line-height:1.25rem;
  color:#cdd9e1;
  background-color:transparent;
  position:relative;
  transition:all .15s ease-in-out
}

.c-header__language-button:hover,.c-header__language-button.is-open{
  color:#3cc8be
}

.c-header__language-switcher{
  position:relative
}

.c-header__languages{
  position:absolute;
  right:0;
  z-index:2;
  margin-top:10px;
  width:150px;
  background-color:#fff
}

.c-header__language{
  transition:all .15s ease-in-out;
  display:block;
  padding:10px;
  border-bottom:1px solid #ebf0f5;
  font-size:14px;
  font-weight:normal;
  text-decoration:none;
  color:#2d373c
}

.c-header__language:hover{
  color:#3cc8be
}

.c-header__inner{
  display:flex;
  justify-content:space-between
}

@media screen and (max-width: 768px){
  .c-header__logo{
    width:170px
  }
}

@media print{
  .no-print,.no-print *{
    display:none !important
  }
}