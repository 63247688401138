@import "@/assets/scss/abstracts/_global.scss";
.c-parent-cat {
    &__heading1 {
        @include t1;
        color: $snow;
        margin-bottom: 24px;
    }
    &__description {
        @include t6;
        color: $snow;
        margin-bottom: 32px;
    }
    &__head {
        margin-bottom: 120px;
        @include mq($screen-l, min) {
            max-width: 60%;
        }
    }
}