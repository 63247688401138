@import "@/assets/scss/abstracts/_global.scss";

.c-link {
  color: #fff;
  font-weight: 700;
  display: inline-flex;
  align-items: center;
  transition: $default-transition;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s ease-in-out;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
