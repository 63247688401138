@import "@/assets/scss/abstracts/_global.scss";

.c-progressbar {
  display: flex;
  width: 100%;
  margin-top: 32px;
  margin-bottom: 52px;

  .c-progressbar__step {
    flex: 1;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    &:not(:last-child) {
      .c-progressbar__link {
        &::before,
        &::after {
          content: "";
          position: absolute;
          z-index: 2;
          display: block;
          box-sizing: border-box;
          transition: $default-transition;
        }

        &::before {
          right: -20px;
          width: 20px;
          height: 44px;
          border-top: 22px solid transparent;
          border-bottom: 22px solid transparent;
          border-left: 20px solid $deep-blue;

          @include mq($screen-s, max) {
            height: 40px;
          }
        }

        &::after {
          right: -18px;
          width: 18px;
          border-top: 20px solid transparent;
          border-bottom: 20px solid transparent;
          border-left: 18px solid $deep-blue-60;
        }

        &:hover::after,
        &.router-link-active:after {
          border-left-color: $teal-light;
        }

        &.is-done:not(.router-link-active) {
          background-color: $teal-light;

          &::after {
            border-left-color: $teal-light;
          }

          &:hover {
            background-color: $teal-light;

            &::after {
              border-left-color: $teal-light;
            }
          }
        }

        &.router-link-active {
          &:before {
            height: calc(100% + 4px);
            border-top: 26px solid transparent;
            border-bottom: 26px solid transparent;
            border-left: 20px solid $deep-blue;
          }

          &::after {
            transition: none !important;
            height: 100%;
            border-top: 24px solid transparent;
            border-bottom: 24px solid transparent;
            border-left: 18px solid $deep-blue-60;
          }

          &:hover {
            transition: none !important;

            &::after {
              border-left: 18px solid $teal-light;
            }
          }
        }
      }
    }
  }

  .c-progressbar__link {
    @include s2;

    width: 100%;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px 0 8px 16px;
    background-color: $deep-blue-60;
    color: #fff;
    transition: $default-transition !important;

    &.router-link-active {
      padding: 12px 0 12px 16px;
      height: 48px;

      @include mq($screen-s, max) {
        height: 44px;
      }
    }

    &.router-link-active {
      position: relative;
      background: linear-gradient(
          to right,
          $teal-light 0%,
          $teal-light calc(50% + 8px),
          $deep-blue-60 calc(50% + 8px),
          $deep-blue-60 100%
      );

      &:hover {
        background: $teal-light;
      }
    }

    &.is-done {
      background: $teal-light;

      .c-progressbar__checked {
        display: inline-block;
      }

      &::after {
        border-left: 18px solid $teal-light !important;
      }

      .c-progressbar__icon {
        display: none;

        &--kitchen {
          display: inline;
        }
      }
    }

    &:hover {
      background: $teal-light;
    }
  }

  .c-progressbar__checked {
    display: none;
  }

  .c-progressbar__step-desc {
    @apply leading-tight;
  }

  @include mq($screen-l, max) {
    .c-progressbar__step-desc {
      display: none;
    }

    .c-progressbar__link:not(.router-link-active) {
      .c-progressbar__icon {
        margin-right: 8px;
      }
    }
  }

  @include mq($screen-m, max) {
    margin-top: 20px;
    margin-bottom: 40px;

    .c-progressbar__link {
      padding-left: 20px;

      &.router-link-active {
        .c-progressbar__icon {
          margin-right: 0;
        }
      }
    }

    &__step-counter {
      margin-left: 4px;
    }
  }

  @media screen and (min-width: $screen-m) and (max-width: $screen-l) {
    .c-progressbar__step-desc {
      .router-link-active & {
        display: block;
      }
    }

    .c-progressbar__link:not(.router-link-active) {
      .c-progressbar__icon {
        display: inline-block;
      }
    }
  }
}

@media print {
  .no-print,
  .no-print * {
    display: none !important;
  }
}
