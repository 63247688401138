.u-flex{
  display:flex
}

.u-inline-flex{
  display:inline-flex
}

.u-h1{
  font-family:Siemens Progress, ui-sans-serif, system-ui, Arial;
  font-size:2.5rem;
  line-height:2.75rem
}

@media (min-width: 768px){
  .u-h1{
    font-size:5rem;
    line-height:5rem
  }
}

.u-h1{
  color:#fff
}

.u-h2{
  font-family:Siemens Progress, ui-sans-serif, system-ui, Arial;
  font-size:1.5rem;
  line-height:1.875rem
}

@media (min-width: 768px){
  .u-h2{
    font-size:1.875rem;
    line-height:2.25rem
  }
}

.u-h2{
  color:#fff
}

@media screen and (max-width: 768px){
  .u-h2{
    font-family:Siemens Progress, ui-sans-serif, system-ui, Arial;
    font-size:1.25rem;
    line-height:1.5rem
  }

  @media (min-width: 768px){
    .u-h2{
      font-size:1.5rem;
      line-height:1.875rem
    }
  }
}

.c-product{
  background-color:#fff;
  position:relative
}

.c-product__energy-label{
  display:block;
  width:100%
}

.c-product__energy-label img{
  max-width:80px;
  margin-left:-7px
}

.c-product--full{
  display:flex;
  width:100%
}

.c-product--full .c-product__size{
  top:32px;
  bottom:auto;
  padding:9px 16px 7px
}

.c-product--full .c-product__img-container,.c-product--full .c-product__info-container{
  flex:1;
  width:50%;
  display:flex;
  align-items:center
}

.c-product__img-container{
  position:relative
}

.c-product__img{
  width:100%;
  height:auto;
  -o-object-fit:cover;
     object-fit:cover
}

.c-product__size{
  font-size:0.875rem;
  line-height:1.25rem;
  background-color:#d4691b;
  display:inline-block;
  padding:3px 8px;
  font-weight:700;
  position:absolute;
  left:0;
  bottom:1rem
}

.c-product__info{
  padding:40px
}

.c-product__title{
  font-family:Siemens Progress, ui-sans-serif, system-ui, Arial;
  font-size:1.25rem;
  line-height:1.5rem
}

@media (min-width: 768px){
  .c-product__title{
    font-size:1.5rem;
    line-height:1.875rem
  }
}

.c-product__title{
  margin-bottom:1rem;
  color:#2b2f43
}

.c-product__description{
  margin-bottom:1rem;
  color:#2b2f43
}

.c-product__description:deep(p),.c-product__description:deep(ul){
  margin-top:1rem;
  margin-bottom:1rem
}

.c-product__description:deep(li){
  color:#2b2f43;
  font-size:1rem;
  padding-left:32px;
  position:relative
}

.c-product__description:deep(li):not(:last-child){
  margin-bottom:8px
}

.c-product__description:deep(li)::before{
  content:"";
  height:1rem;
  width:1rem;
  display:block;
  position:absolute;
  left:0;
  top:50%;
  transform:translateY(-50%);
  background-image:url("data:image/svg+xml,%0A%3Csvg width='16' height='14' viewBox='0 0 16 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M14.3438 0.96875L16 2.625L5.59375 13.0312L0 7.4375L1.65625 5.78125L5.25 8.875L14.3438 0.96875Z' fill='%233CC8BE'/%3E%3C/svg%3E%0A");
  background-repeat:no-repeat
}

.c-product__feature-text{
  font-family:Siemens Progress, ui-sans-serif, system-ui, Arial;
  font-size:1.125rem;
  line-height:1.75rem
}

@media (min-width: 768px){
  .c-product__feature-text{
    font-size:1.25rem;
    line-height:1.5rem
  }
}

.c-product__feature-text{
  color:#3c464b
}

.c-product__instructions{
  margin-top:1rem;
  line-height:20px
}

.c-product .c-step__button-wrapper{
  margin-top:30px
}

@media screen and (min-width: 1280px){
  .c-product__size{
    bottom:120px
  }
}

@media screen and (max-width: 768px){
  .c-product--full{
    flex-direction:column
  }

  .c-product--full .c-product__size{
    bottom:1rem;
    top:auto
  }

  .c-product--full .c-product__img-container,.c-product--full .c-product__info-container{
    flex:1;
    width:100%
  }

  .c-product__info{
    padding:20px
  }
}

@media screen and (min-width: 768px){
  .c-product--full:not(.c-product--img-right) .c-product__size{
    right:0;
    left:auto
  }

  .c-product--img-right .c-product__img-container{
    order:2
  }
}