.c-input {
  @apply block w-full transition text-white bg-transparent pb-2 outline-none focus:border-brand-vibrant-petrol overflow-ellipsis;

  border: none;
  border-bottom: 2px solid #fff;

  &.c-input--dark {
    @apply text-brand-deep-blue-2 focus:border-brand-vibrant-petrol;

    border-bottom: 2px solid #000;
  }
}
