.l-container {
    max-width: 1440px;
    width: 100%;
    margin: 0 auto;
    padding-left: 40px;
    padding-right: 40px;
    position: relative;
    @include mq($screen-s, max) {
        padding-left: 20px;
        padding-right: 20px;
    }
}