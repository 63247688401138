@import "@/assets/scss/abstracts/_global.scss";

.c-language-switcher {
  position: relative;

  .c-language-switcher__button {
    @apply text-brand-deep-blue-4;

    background-color: transparent;
    position: relative;
    transition: $default-transition;

    &:hover, &.is-open {
      color: $teal-light;
    }
  }

  .c-language-switcher__languages {
    position: absolute;
    right: 0;
    z-index: 2;
    margin-top: 10px;
    width: 150px;
    background-color: $snow;
  }

  .c-language-switcher__language {
    transition: $default-transition;
    display: block;
    padding: 10px;
    border-bottom: 1px solid $stone-18;
    font-weight: normal;
    text-decoration: none;
    color: $stone-1;

    &:hover {
      color: $teal-light;
      cursor: pointer;
    }
  }
}
