.u-flex{
  display:flex
}

.u-inline-flex{
  display:inline-flex
}

.u-h1{
  font-family:Siemens Progress, ui-sans-serif, system-ui, Arial;
  font-size:2.5rem;
  line-height:2.75rem
}

@media (min-width: 768px){
  .u-h1{
    font-size:5rem;
    line-height:5rem
  }
}

.u-h1{
  color:#fff
}

.u-h2{
  font-family:Siemens Progress, ui-sans-serif, system-ui, Arial;
  font-size:1.5rem;
  line-height:1.875rem
}

@media (min-width: 768px){
  .u-h2{
    font-size:1.875rem;
    line-height:2.25rem
  }
}

.u-h2{
  color:#fff
}

@media screen and (max-width: 768px){
  .u-h2{
    font-family:Siemens Progress, ui-sans-serif, system-ui, Arial;
    font-size:1.25rem;
    line-height:1.5rem
  }

  @media (min-width: 768px){
    .u-h2{
      font-size:1.5rem;
      line-height:1.875rem
    }
  }
}

.c-media-footer{
  background-position:center center;
  background-size:cover;
  background-repeat:no-repeat;
  height:100%;
  padding-bottom:39.2156862745%;
  position:relative
}

.c-media-footer.is-video{
  cursor:pointer
}

.c-media-footer__play{
  position:absolute;
  top:50%;
  left:50%;
  transform:translate(-50%, -50%);
  color:#fff
}

.c-media-footer__video{
  position:absolute;
  top:0;
  left:0;
  width:100%;
  height:100%;
  border:0;
  background-color:#000
}

.c-media-footer__video:focus{
  outline:none
}