@import "@/assets/scss/abstracts/_global.scss";

.c-header {
  padding-top: 45px;
  padding-bottom: 45px;
  display: flex;
  justify-content: space-between;

  @include mq($screen-m, max) {
    padding-top: 25px;
    padding-bottom: 25px;
  }

  &__right {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    @include mq($screen-s, max) {
      justify-content: center;
    }
  }

  &__language-button {
    @include s2;
    color: $stone-8;
    background-color: transparent;
    position: relative;
    transition: $default-transition;

    &:hover, &.is-open {
      color: $teal-light;
    }
  }

  &__language-switcher {
    position: relative;
  }

  &__languages {
    position: absolute;
    right: 0;
    z-index: 2;
    margin-top: 10px;
    width: 150px;
    background-color: $snow;
  }

  &__language {
    transition: $default-transition;
    display: block;
    padding: 10px;
    border-bottom: 1px solid $stone-18;
    font-size: 14px;
    font-weight: normal;
    text-decoration: none;
    color: $stone-1;

    &:hover {
      color: $teal-light;
    }
  }

  &__inner {
    display: flex;
    justify-content: space-between;
  }

  @include mq($screen-m, max) {
    &__logo {
      width: 170px;
    }
  }
}

@media print {
  .no-print, .no-print * {
    display: none !important;
  }
}
