@font-face {
  font-family: "Siemens Sans";
  src: local("Siemens Sans"), url(~@/assets/fonts/SiemensSansOT-Regular.otf) format("opentype");
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: "Siemens Sans";
  src: local("Siemens Sans"), url(~@/assets/fonts/SiemensSansOT-Bold.otf) format("opentype");
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: "Siemens Sans";
  src: local("Siemens Sans"), url(~@/assets/fonts/SiemensSansOT-Italic.otf) format("opentype");
  font-style: italic;
  font-weight: 400;
}

@font-face {
  font-family: "Siemens Sans";
  src: local("Siemens Sans"), url(~@/assets/fonts/SiemensSansOT-BoldItalic.otf) format("opentype");
  font-style: italic;
  font-weight: 700;
}

@font-face {
  font-family: "Siemens Progress";
  src: local("Siemens Progress"), url(~@/assets/fonts/SiemensProgress-Regular.otf) format("opentype");
  font-style: normal;
  font-weight: 400
}

@font-face {
  font-family: "Siemens Progress";
  src: local("Siemens Progress"), url(~@/assets/fonts/SiemensProgress-Bold.otf) format("opentype");
  font-style: normal;
  font-weight: 700;
}
