.u-flex{
  display:flex
}

.u-inline-flex{
  display:inline-flex
}

.u-h1{
  font-family:Siemens Progress, ui-sans-serif, system-ui, Arial;
  font-size:2.5rem;
  line-height:2.75rem
}

@media (min-width: 768px){
  .u-h1{
    font-size:5rem;
    line-height:5rem
  }
}

.u-h1{
  color:#fff
}

.u-h2{
  font-family:Siemens Progress, ui-sans-serif, system-ui, Arial;
  font-size:1.5rem;
  line-height:1.875rem
}

@media (min-width: 768px){
  .u-h2{
    font-size:1.875rem;
    line-height:2.25rem
  }
}

.u-h2{
  color:#fff
}

@media screen and (max-width: 768px){
  .u-h2{
    font-family:Siemens Progress, ui-sans-serif, system-ui, Arial;
    font-size:1.25rem;
    line-height:1.5rem
  }

  @media (min-width: 768px){
    .u-h2{
      font-size:1.5rem;
      line-height:1.875rem
    }
  }
}

.c-my-kitchen .downloadLoading{
  opacity:.6
}

.c-my-kitchen .downloadLoading:after{
  overflow:hidden;
  display:inline-block;
  vertical-align:bottom;
  animation:ellipsis steps(4, end) 1200ms infinite;
  content:"…";
  width:0
}

.c-my-kitchen__progressbar{
  margin-top:32px;
  margin-bottom:84px
}

.c-my-kitchen__heading1{
  font-family:Siemens Progress, ui-sans-serif, system-ui, Arial;
  font-size:2.25rem;
  line-height:2.75rem
}

@media (min-width: 768px){
  .c-my-kitchen__heading1{
    font-size:3.375rem;
    line-height:3.75rem
  }
}

.c-my-kitchen__heading1{
  color:#fff
}

.c-my-kitchen__buttons{
  display:flex;
  gap:12px
}

@media screen and (max-width: 768px){
  .c-my-kitchen__buttons{
    flex-wrap:wrap;
    margin-top:1rem;
    margin-bottom:1rem
  }
}

.c-my-kitchen__head{
  margin-bottom:120px
}

.c-my-kitchen__save{
  display:flex;
  align-items:center
}

.c-my-kitchen__save-link{
  margin-left:32px
}

.c-my-kitchen .email--wrapper{
  margin-left:28px
}

@media screen and (max-width: 768px){
  .c-my-kitchen .email--wrapper{
    margin-left:0px
  }
}

.c-my-kitchen .label--notification{
  color:#3cc8be
}

@media screen and (max-width: 768px){
  .c-my-kitchen__link-container-wrapper{
    width:100%
  }
}

@media screen and (max-width: 768px){
  .c-my-kitchen__link-container-wrapper button{
    width:100%
  }
}

.c-my-kitchen__link-container{
  display:flex;
  align-items:center
}

.c-my-kitchen__link-list a{
  margin-right:16px;
  display:inline-block
}

@media screen and (max-width: 640px){
  .c-my-kitchen__link-list a{
    display:inherit;
    margin-top:16px
  }
}

.c-my-kitchen__link-label{
  color:#fff;
  margin-bottom:4px;
  line-height:24px
}

.c-my-kitchen__link{
  padding:10px;
  width:300px;
  font-size:0.875rem;
  line-height:1.25rem
}

.c-my-kitchen__products{
  display:flex;
  width:100%;
  gap:40px
}

.c-my-kitchen__product{
  width:100%
}

.c-my-kitchen__product-bundle{
  border:4px solid #3cc8be;
  padding:40px;
  margin-bottom:40px;
  position:relative
}

@media screen and (max-width: 640px){
  .c-my-kitchen__product-bundle{
    padding:20px
  }
}

.c-my-kitchen__product-bundle::before{
  content:"Bundle";
  position:absolute;
  top:-15px;
  left:40px;
  background-color:#3cc8be;
  border-left:4px solid #000028;
  border-right:4px solid #000028;
  padding-left:16px;
  padding-right:16px;
  color:#fff;
  line-height:28px
}

.c-my-kitchen__product-bundle:lang(se)::before{
  content:"Paket"
}

.c-my-kitchen__product-bundle:lang(en)::before{
  content:"Bundle"
}

.c-my-kitchen__product-bundle{
  width:100%
}

.c-my-kitchen__product-bundle__products{
  display:flex;
  align-items:center;
  align-items:stretch
}

@media screen and (max-width: 768px){
  .c-my-kitchen__product-bundle__products{
    flex-direction:column
  }
}

.c-my-kitchen__product-bundle__products .c-child-product{
  flex:1
}

@media screen and (max-width: 768px){
  .c-my-kitchen__product-bundle__products .c-child-product{
    max-width:100%
  }
}

.c-my-kitchen__product-bundle-plus{
  color:#3cc8be;
  margin-left:20px;
  margin-right:20px;
  min-width:40px;
  align-self:center
}

@media screen and (max-width: 768px){
  .c-my-kitchen__product-bundle-plus{
    margin-top:20px;
    margin-bottom:20px
  }
}

.c-my-kitchen__bundle{
  border:4px solid #3cc8be;
  padding:40px;
  position:relative
}

.c-my-kitchen__bundle::before{
  content:"Bundle";
  position:absolute;
  top:-15px;
  left:40px;
  background-color:#3cc8be;
  border-left:4px solid #2d373c;
  border-right:4px solid #2d373c;
  padding-left:16px;
  padding-right:16px;
  color:#fff;
  line-height:28px
}

.c-my-kitchen__bundle-products{
  display:flex;
  align-items:center;
  align-items:stretch
}

.c-my-kitchen__bundle-plus{
  color:#3cc8be;
  margin-left:20px;
  margin-right:20px;
  min-width:40px;
  align-self:center
}

.c-my-kitchen__section,.c-my-kitchen__textmedia{
  margin-bottom:60px
}

.c-my-kitchen__section-head{
  display:flex;
  justify-content:space-between;
  margin-bottom:32px
}

@media screen and (max-width: 768px){
  .c-my-kitchen__section-head{
    margin-bottom:0
  }
}

.c-my-kitchen__back{
  border-top:1px solid #3c464b;
  padding-top:42px;
  margin-bottom:122px
}

@media screen and (max-width: 768px){
  .c-my-kitchen__head{
    margin-bottom:40px
  }

  .c-my-kitchen__product:not(:last-child){
    margin-right:0;
    margin-bottom:40px
  }

  .c-my-kitchen__bundle-products,.c-my-kitchen__products{
    flex-direction:column
  }

  .c-my-kitchen__bundle-plus{
    margin-top:20px;
    margin-bottom:20px
  }

  .c-my-kitchen__textmedia,.c-my-kitchen__section{
    margin-bottom:40px
  }

  .c-my-kitchen__section-head{
    flex-direction:column
  }

  .c-my-kitchen__heading1{
    margin-bottom:20px
  }

  .c-my-kitchen__save{
    flex-direction:column;
    align-items:flex-start
  }

  .c-my-kitchen__link-container{
    margin-bottom:16px;
    flex-direction:column;
    position:relative;
    width:100%
  }

  .c-my-kitchen__save-link{
    margin-left:0
  }

  .c-my-kitchen__link{
    padding:10px;
    width:100%
  }
}

@media screen and (max-width: 640px){
  .c-my-kitchen__product:not(:last-child){
    margin-bottom:20px
  }

  .c-my-kitchen__bundle{
    padding:20px
  }
}

.only-for-printing{
  margin-top:10px;
  display:none
}

@media print{
  .no-print,.no-print *{
    display:none !important
  }

  .c-my-kitchen__link{
    width:100%
  }

  .only-for-printing{
    display:block
  }
}

@keyframes ellipsis{
  to{
    width:20px
  }
}