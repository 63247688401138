@import "@/assets/scss/abstracts/_global.scss";
/* Only global styles should be imported here, component specific styles will
/* be imported inside the component files.
/* ------------------------------------------------------------------------------ */

@tailwind base;
@tailwind components;
@tailwind utilities;

//Base
@import "base/reset";
@import "base/fonts";
@import "base/base";
@import "base/links";
@import "base/form";
@import "base/loader";
@import "base/transitions";
//Layout
@import "layout/global";
