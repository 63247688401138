@import "@/assets/scss/abstracts/_global.scss";

.imageslider {
  img {
    width: 100%;
    height: 100%;
    max-height: 600px;
    object-fit: contain;
    object-position: center;
    display: block;
    background: #fff;
  }

  li {
    display: flex;
    align-items: center;
  }
}

.splide {
  background: #fff;
  position: relative;
  height: 100%;

  &__video {
    video {
      background: #fff;
      object-fit: cover;
    }
  }
}

.imageslider {
  position: relative;
  width: 100%;
}

.splide__arrow {
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: auto;
  padding: 20px 40px;
  color: #fff;
  font-weight: bold;
  font-size: 18px;
  transition: 0.7s ease;
  text-decoration: none;
  user-select: none;
  z-index: 1;
  background: none;

  svg {
    display: none;
  }
}

.splide__track {
  height: 100%;

  .splide__list {
    height: 100%;

    .splide__slide {
      height: 100%;
    }
  }
}

.splide__arrow--next {
  right: 0;

  &::after {
    content: $icon-chevron-right;
  }
}

.splide__arrow--prev {
  left: 0;

  &::after {
    content: $icon-chevron-left;
  }
}
