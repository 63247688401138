.u-flex{
  display:flex
}

.u-inline-flex{
  display:inline-flex
}

.u-h1{
  font-family:Siemens Progress, ui-sans-serif, system-ui, Arial;
  font-size:2.5rem;
  line-height:2.75rem
}

@media (min-width: 768px){
  .u-h1{
    font-size:5rem;
    line-height:5rem
  }
}

.u-h1{
  color:#fff
}

.u-h2{
  font-family:Siemens Progress, ui-sans-serif, system-ui, Arial;
  font-size:1.5rem;
  line-height:1.875rem
}

@media (min-width: 768px){
  .u-h2{
    font-size:1.875rem;
    line-height:2.25rem
  }
}

.u-h2{
  color:#fff
}

@media screen and (max-width: 768px){
  .u-h2{
    font-family:Siemens Progress, ui-sans-serif, system-ui, Arial;
    font-size:1.25rem;
    line-height:1.5rem
  }

  @media (min-width: 768px){
    .u-h2{
      font-size:1.5rem;
      line-height:1.875rem
    }
  }
}

.c-language-switcher{
  position:relative
}

.c-language-switcher .c-language-switcher__button{
  --tw-text-opacity:1;
  color:rgba(163, 172, 189, var(--tw-text-opacity));
  background-color:transparent;
  position:relative;
  transition:all .15s ease-in-out
}

.c-language-switcher .c-language-switcher__button:hover,.c-language-switcher .c-language-switcher__button.is-open{
  color:#3cc8be
}

.c-language-switcher .c-language-switcher__languages{
  position:absolute;
  right:0;
  z-index:2;
  margin-top:10px;
  width:150px;
  background-color:#fff
}

.c-language-switcher .c-language-switcher__language{
  transition:all .15s ease-in-out;
  display:block;
  padding:10px;
  border-bottom:1px solid #ebf0f5;
  font-weight:normal;
  text-decoration:none;
  color:#2d373c
}

.c-language-switcher .c-language-switcher__language:hover{
  color:#3cc8be;
  cursor:pointer
}