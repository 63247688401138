@import "@/assets/scss/abstracts/_global.scss";

.c-btn {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 8px 24px 8px 24px;
  border: 1px solid $teal-light;
  border-radius: 2px;
  text-align: center;
  transition: $default-transition;
  cursor: pointer;
  min-width: 230px;
  font-weight: 700;

  &:first-letter {
    text-transform: uppercase;
  }

  span:first-letter {
    text-transform: uppercase;
  }

  &:disabled {
    cursor: not-allowed;
    background: $teal-extralight;
    color: #fff;
    border-color: $teal-extralight;
  }

  &.c-btn--primary {
    background: $teal-light;
    color: #fff;

    &:hover {
      background: $teal-dark;
      color: #fff;
      border-color: $teal-dark;
    }

    &:disabled {
      cursor: not-allowed;
      background: $teal-extralight;
      color: #fff;
      border-color: $teal-extralight;

      &:hover {
        background: $teal-extralight;
        color: #fff;
        border-color: $teal-extralight;
      }
    }
  }

  &.c-btn--secondary {
    background: transparent;
    color: $teal-light;
    border-color: $teal-light;

    &:hover {
      color: $teal-light;
      border-color: $teal-light;
      outline: 1px solid $teal-light;
    }

    &:disabled {
      background: transparent;
      cursor: not-allowed;
      color: $teal-light;
      border-color: $teal-light;
      opacity: 0.4;

      &:hover {
        color: $teal-light;
        border-color: $teal-light;
        outline: none;
      }
    }
  }
}
