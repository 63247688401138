body {
  @apply bg-brand-deep-blue leading-normal;
}

* {
  font-family: 'Siemens Sans', 'sans-serif';
}

.text-content {
  color: $snow;

  h1 {
    @apply mb-6;

    @include t2;

    @include mq($screen-m, max) {
      @include t3;
    }
  }

  h2 {
    @apply mb-6;

    @include t3;

    @include mq($screen-m, max) {
      @include t4;
    }
  }

  h3 {
    @apply mb-6;

    @include t4;

    @include mq($screen-m, max) {
      @include t5;
    }
  }

  h4 {
    @apply mb-4;

    @include t5;

    @include mq($screen-m, max) {
      @include t6;
    }
  }

  a {
    @apply underline;

    &:hover {
      @apply no-underline;
    }
  }

  p {
    @apply mb-4;
  }

  blockquote {
    @apply border-l-2 border-solid border-white pl-4 mb-4;
  }

  ul {
    @apply list-disc pl-4 mb-4;
  }

  ol {
    @apply list-decimal pl-5 mb-4;
  }

  img {
    @apply max-w-full h-auto;
  }
}
