.u-flex{
  display:flex
}

.u-inline-flex{
  display:inline-flex
}

.u-h1{
  font-family:Siemens Progress, ui-sans-serif, system-ui, Arial;
  font-size:2.5rem;
  line-height:2.75rem
}

@media (min-width: 768px){
  .u-h1{
    font-size:5rem;
    line-height:5rem
  }
}

.u-h1{
  color:#fff
}

.u-h2{
  font-family:Siemens Progress, ui-sans-serif, system-ui, Arial;
  font-size:1.5rem;
  line-height:1.875rem
}

@media (min-width: 768px){
  .u-h2{
    font-size:1.875rem;
    line-height:2.25rem
  }
}

.u-h2{
  color:#fff
}

@media screen and (max-width: 768px){
  .u-h2{
    font-family:Siemens Progress, ui-sans-serif, system-ui, Arial;
    font-size:1.25rem;
    line-height:1.5rem
  }

  @media (min-width: 768px){
    .u-h2{
      font-size:1.5rem;
      line-height:1.875rem
    }
  }
}

.c-step{
  padding-bottom:120px;
  position:relative
}

.c-step__head{
  display:flex
}

.c-step__head .c-step__heading{
  margin-top:18px
}

.c-step__counter{
  font-family:Siemens Progress, ui-sans-serif, system-ui, Arial;
  font-size:1.125rem;
  line-height:1.75rem
}

@media (min-width: 768px){
  .c-step__counter{
    font-size:1.25rem;
    line-height:1.5rem
  }
}

.c-step__counter{
  color:#fff;
  padding:24px 32px;
  background:#7b89a1;
  margin-right:24px
}

.c-step__heading{
  margin-bottom:1rem
}

.c-step__description{
  color:#fff;
  margin-bottom:24px
}

.c-step__description--hint{
  font-size:0.875rem;
  line-height:1.25rem;
  margin-bottom:40px;
  display:flex;
  align-items:center
}

.c-step__basics{
  display:flex;
  gap:8px;
  margin-bottom:80px
}

.c-step__basics--alternatives{
  margin-bottom:40px
}

.c-step__button-wrapper{
  display:flex;
  justify-content:center;
  margin-top:32px;
  margin-bottom:32px
}

.c-step__addedProduct-badge{
  background-color:#3cc8be;
  padding:10px 20px 10px 10px;
  margin-right:10px
}

.c-step__addedProduct-badge .c-icon{
  vertical-align:middle;
  transform:translateY(-2px)
}

.c-step__result-features h3{
  color:#fff;
  font-size:22px;
  font-weight:bold;
  margin-bottom:24px
}

@media screen and (min-width: 1024px){
  .c-step__head{
    max-width:60%
  }
}

@media screen and (max-width: 768px){
  .c-step{
    padding-bottom:80px
  }

  .c-step__heading{
    margin-bottom:16px
  }

  .c-step__basics{
    margin-bottom:40px;
    flex-wrap:wrap
  }

  .c-step__head--step{
    margin-bottom:40px
  }

  .c-step__head--step .c-alternatives__heading{
    margin-top:0
  }

  .c-step__button-wrapper{
    margin-top:40px
  }

  .c-step__counter{
    padding:16px
  }

  .c-step__description{
    margin-bottom:16px
  }

  .c-step__description--hint{
    margin-bottom:24px
  }
}

@media screen and (max-width: 640px){
  .c-step__basics{
    margin-bottom:40px
  }
}

.c-results__result{
  padding-bottom:40px
}

.c-results__result:not(:last-child){
  margin-bottom:80px
}

.c-results__result:last-child{
  margin-bottom:50px
}

.c-result{
  padding-bottom:40px;
  margin-bottom:50px
}

.c-result__feature-description{
  color:#fff;
  margin-bottom:40px
}

.c-result__product{
  margin-bottom:56px
}

.c-result__product-bundle{
  border:4px solid #3cc8be;
  padding:40px;
  position:relative
}

.c-result__product-bundle::before{
  content:"Bundle";
  position:absolute;
  top:-15px;
  left:40px;
  background-color:#3cc8be;
  border-left:4px solid #2d373c;
  border-right:4px solid #2d373c;
  padding-left:16px;
  padding-right:16px;
  color:#fff;
  line-height:28px
}

.c-result__product-bundle__products{
  display:flex;
  align-items:center;
  align-items:stretch
}

.c-result__product-bundle-plus{
  color:#3cc8be;
  margin-left:20px;
  margin-right:20px;
  min-width:40px;
  align-self:center
}

.c-result .c-step__child-step{
  border:4px solid #3cc8be;
  margin-top:16px;
  padding:16px;
  margin-bottom:16px
}

.c-alternatives__section{
  padding-bottom:40px;
  border-bottom:4px solid #3cc8be
}

.c-alternatives__section:not(:last-child){
  margin-bottom:80px
}

.c-alternatives__section:last-child{
  margin-bottom:50px
}

.c-alternatives__extras-heading{
  font-family:Siemens Progress, ui-sans-serif, system-ui, Arial;
  font-size:1.25rem;
  line-height:1.5rem
}

@media (min-width: 768px){
  .c-alternatives__extras-heading{
    font-size:1.5rem;
    line-height:1.875rem
  }
}

.c-alternatives__extras-heading{
  color:#fff;
  margin-bottom:24px
}

.c-alternatives__main-feature{
  margin-bottom:8px
}

.c-features{
  padding-bottom:40px;
  margin-bottom:1rem
}

.c-features__head{
  display:flex;
  margin-bottom:56px
}

.c-feature-heading{
  font-family:Siemens Progress, ui-sans-serif, system-ui, Arial;
  font-size:1.25rem;
  line-height:1.5rem
}

@media (min-width: 768px){
  .c-feature-heading{
    font-size:1.5rem;
    line-height:1.875rem
  }
}

.c-feature-heading{
  font-weight:bold;
  margin-bottom:24px;
  margin-top:24px;
  color:#fff
}