@import "@/assets/scss/abstracts/_global.scss";

.c-my-kitchen {
  .downloadLoading {
    opacity: 0.6;

    &:after {
      overflow: hidden;
      display: inline-block;
      vertical-align: bottom;
      -webkit-animation: ellipsis steps(4, end) 1200ms infinite;
      animation: ellipsis steps(4, end) 1200ms infinite;
      content: "\2026";
      /* ascii code for the ellipsis character */
      width: 0;
    }
  }

  &__progressbar {
    margin-top: 32px;
    margin-bottom: 84px;
  }

  &__heading1 {
    @include t2;
    color: #fff;
  }

  &__buttons {
    display: flex;
    gap: 12px;

    @include mq($screen-m, max) {
      flex-wrap: wrap;
      margin-top: 1rem;
      margin-bottom: 1rem;
    }
  }

  &__head {
    margin-bottom: 120px;

    @include mq($screen-l, min) {
      /* max-width: 60%; */
    }
  }

  &__save {
    display: flex;
    align-items: center;
  }

  &__save-link {
    margin-left: 32px;
  }

  .email--wrapper {
    margin-left: 28px;

    @include mq($screen-m, max) {
      margin-left: 0px;
    }
  }

  .label--notification {
    color: $teal-light;
  }

  &__link-container-wrapper {
    @include mq($screen-m, max) {
      width: 100%;
    }

    button {
      @include mq($screen-m, max) {
        width: 100%;
      }
    }
  }

  &__link-container {
    display: flex;
    align-items: center;
  }

  &__link-list {
    a {
      margin-right: 16px;
      display: inline-block;

      @include mq($screen-s, max) {
        display: inherit;
        margin-top: 16px;
      }
    }
  }

  &__link-label {
    color: #fff;
    margin-bottom: 4px;
    line-height: 24px;
  }

  &__link {
    padding: 10px;
    width: 300px;
    @include s2;
  }

  &__products {
    display: flex;
    width: 100%;
    gap: 40px;
  }

  &__product {
    &-bundle {
      border: 4px solid $teal-light;
      padding: 40px;
      margin-bottom: 40px;
      position: relative;

      @include mq($screen-s, max) {
        padding: 20px;
      }

      &::before {
        content: "Bundle";
        position: absolute;
        top: -15px;
        left: 40px;
        background-color: $teal-light;
        border-left: 4px solid $deep-blue;
        border-right: 4px solid $deep-blue;
        padding-left: 16px;
        padding-right: 16px;
        color: #fff;
        line-height: 28px;
      }

      &:lang(se) {
        &::before {
          content: "Paket";
        }
      }

      &:lang(en) {
        &::before {
          content: "Bundle";
        }
      }
    }

    &-bundle {
      width: 100%;

      &__products {
        display: flex;
        align-items: center;
        align-items: stretch;

        @include mq($screen-m, max) {
          flex-direction: column;
        }

        .c-child-product {
          flex: 1;

          @include mq($screen-m, max) {
            max-width: 100%;
          }
        }
      }
    }

    &-bundle-plus {
      color: $teal-light;
      margin-left: 20px;
      margin-right: 20px;
      min-width: 40px;
      align-self: center;

      @include mq($screen-m, max) {
        margin-top: 20px;
        margin-bottom: 20px;
      }
    }

    width: 100%;
  }

  &__bundle {
    border: 4px solid $teal-light;
    padding: 40px;
    position: relative;

    &::before {
      content: "Bundle";
      position: absolute;
      top: -15px;
      left: 40px;
      background-color: $teal-light;
      border-left: 4px solid $stone-1;
      border-right: 4px solid $stone-1;
      padding-left: 16px;
      padding-right: 16px;
      color: #fff;
      line-height: 28px;
    }
  }

  &__bundle-products {
    display: flex;
    align-items: center;
    align-items: stretch;
  }

  &__bundle-plus {
    color: $teal-light;
    margin-left: 20px;
    margin-right: 20px;
    min-width: 40px;
    align-self: center;
  }

  &__section,
  &__textmedia {
    margin-bottom: 60px;
  }

  &__section-head {
    display: flex;
    justify-content: space-between;
    margin-bottom: 32px;

    @include mq($screen-m, max) {
      margin-bottom: 0;
    }
  }

  &__back {
    border-top: 1px solid $stone-dark;
    padding-top: 42px;
    margin-bottom: 122px;
  }

  @include mq($screen-m, max) {
    &__head {
      margin-bottom: 40px;
    }

    &__product:not(:last-child) {
      margin-right: 0;
      margin-bottom: 40px;
    }

    &__bundle-products,
    &__products {
      flex-direction: column;
    }

    &__bundle-plus {
      margin-top: 20px;
      margin-bottom: 20px;
    }

    &__textmedia,
    &__section {
      margin-bottom: 40px;
    }

    &__section-head {
      flex-direction: column;
    }

    &__heading1 {
      margin-bottom: 20px;
    }

    &__save {
      flex-direction: column;
      align-items: flex-start;
    }

    &__link-container {
      margin-bottom: 16px;
      flex-direction: column;
      position: relative;
      width: 100%;
    }

    &__save-link {
      margin-left: 0;
    }

    &__link {
      padding: 10px;
      width: 100%;
    }
  }

  @include mq($screen-s, max) {
    &__product:not(:last-child) {
      margin-bottom: 20px;
    }

    &__bundle {
      padding: 20px;
    }
  }
}

.only-for-printing {
  margin-top: 10px;
  display: none;
}

@media print {

  .no-print,
  .no-print * {
    display: none !important;
  }

  .c-my-kitchen__link {
    width: 100%;
  }

  .only-for-printing {
    display: block;
  }
}

@keyframes ellipsis {
  to {
    width: 20px;
  }
}

@-webkit-keyframes ellipsis {
  to {
    width: 20px;
  }
}
