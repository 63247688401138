@import "@/assets/scss/abstracts/_global.scss";

.c-product {
  background-color: $snow;
  position: relative;

  &__energy-label {
    display: block;

    img {
      max-width: 80px;
      margin-left: -7px;
    }

    width: 100%;
  }

  &--full {
    display: flex;
    width: 100%;

    .c-product__size {
      top: 32px;
      bottom: auto;
      padding: 9px 16px 7px;
    }

    .c-product__img-container,
    .c-product__info-container {
      flex: 1;
      width: 50%;
      display: flex;
      align-items: center;
    }
  }

  &__img-container {
    position: relative;
  }

  &__img {
    width: 100%;
    height: auto;
    object-fit: cover;
  }

  &__size {
    @include s2;

    background-color: $orange;
    display: inline-block;
    padding: 3px 8px;
    font-weight: 700;
    position: absolute;
    left: 0;
    bottom: 1rem;
  }

  &__info {
    padding: 40px;
  }

  &__title {
    @include t5;

    margin-bottom: 1rem;
    color: $deep-blue-2;
  }

  &__description {
    margin-bottom: 1rem;
    color: $deep-blue-2;

    &:deep(p),
    &:deep(ul) {
      margin-top: 1rem;
      margin-bottom: 1rem;
    }

    &:deep(li) {
      color: $deep-blue-2;
      font-size: 1rem;
      padding-left: 32px;
      position: relative;

      &:not(:last-child) {
        margin-bottom: 8px;
      }

      &::before {
        content: "";
        height: 1rem;
        width: 1rem;
        display: block;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        background-image: url("data:image/svg+xml,%0A%3Csvg width='16' height='14' viewBox='0 0 16 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M14.3438 0.96875L16 2.625L5.59375 13.0312L0 7.4375L1.65625 5.78125L5.25 8.875L14.3438 0.96875Z' fill='%233CC8BE'/%3E%3C/svg%3E%0A");
        background-repeat: no-repeat;
      }
    }
  }

  &__feature-text {
    @include t6;
    color: $stone-dark;
  }

  &__instructions {
    @apply mt-4;
    line-height: 20px;
  }

  .c-step__button-wrapper {
    margin-top: 30px;
  }

  @include mq($screen-xl, min) {
    &__size {
      bottom: 120px;
    }
  }

  @include mq($screen-m, max) {
    &--full {
      flex-direction: column;

      .c-product__size {
        bottom: 1rem;
        top: auto;
      }

      .c-product__img-container,
      .c-product__info-container {
        flex: 1;
        width: 100%;
      }
    }

    &__info {
      padding: 20px;
    }
  }

  @include mq($screen-m, min) {
    &--full {
      &:not(.c-product--img-right) {
        .c-product__size {
          right: 0;
          left: auto;
        }
      }
    }

    &--img-right {
      .c-product__img-container {
        order: 2;
      }
    }
  }
}
