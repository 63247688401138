@import "@/assets/scss/abstracts/_global.scss";

.c-parent-cat__heading1 {
  @apply mb-10;
  @include t2;

  color: #fff;
}

.c-parent-cat__description {
  @apply mb-10;

  color: #fff;
}
