@import "@/assets/scss/abstracts/_global.scss";

.c-icon {
  display: inline-block;
  width: 16px;
  height: 16px;
  stroke-width: 0;
  stroke: currentColor;
  fill: currentColor;

  &--small {
    width: 12px;
    height: 12px;
  }

  &--large {
    width: 24px;
    height: 24px;
    @include mq($screen-m, max) {
      width: 16px;
      height: 16px;
    }
  }

  &--xl {
    width: 40px;
    height: 40px;
  }

  &--xxl {
    width: 96px;
    height: 96px;
  }

  &--before {
    margin-right: 8px;
  }

  &--after {
    margin-left: 8px;
  }
}
