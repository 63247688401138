.u-flex{
  display:flex
}

.u-inline-flex{
  display:inline-flex
}

.u-h1{
  font-family:Siemens Progress, ui-sans-serif, system-ui, Arial;
  font-size:2.5rem;
  line-height:2.75rem
}

@media (min-width: 768px){
  .u-h1{
    font-size:5rem;
    line-height:5rem
  }
}

.u-h1{
  color:#fff
}

.u-h2{
  font-family:Siemens Progress, ui-sans-serif, system-ui, Arial;
  font-size:1.5rem;
  line-height:1.875rem
}

@media (min-width: 768px){
  .u-h2{
    font-size:1.875rem;
    line-height:2.25rem
  }
}

.u-h2{
  color:#fff
}

@media screen and (max-width: 768px){
  .u-h2{
    font-family:Siemens Progress, ui-sans-serif, system-ui, Arial;
    font-size:1.25rem;
    line-height:1.5rem
  }

  @media (min-width: 768px){
    .u-h2{
      font-size:1.5rem;
      line-height:1.875rem
    }
  }
}

.c-parent-cat__heading1{
  margin-bottom:2.5rem;
  font-family:Siemens Progress, ui-sans-serif, system-ui, Arial;
  font-size:2.25rem;
  line-height:2.75rem
}

@media (min-width: 768px){
  .c-parent-cat__heading1{
    font-size:3.375rem;
    line-height:3.75rem
  }
}

.c-parent-cat__heading1{
  color:#fff
}

.c-parent-cat__description{
  margin-bottom:2.5rem;
  color:#fff
}