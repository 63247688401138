.u-flex{
  display:flex
}

.u-inline-flex{
  display:inline-flex
}

.u-h1{
  font-family:Siemens Progress, ui-sans-serif, system-ui, Arial;
  font-size:2.5rem;
  line-height:2.75rem
}

@media (min-width: 768px){
  .u-h1{
    font-size:5rem;
    line-height:5rem
  }
}

.u-h1{
  color:#fff
}

.u-h2{
  font-family:Siemens Progress, ui-sans-serif, system-ui, Arial;
  font-size:1.5rem;
  line-height:1.875rem
}

@media (min-width: 768px){
  .u-h2{
    font-size:1.875rem;
    line-height:2.25rem
  }
}

.u-h2{
  color:#fff
}

@media screen and (max-width: 768px){
  .u-h2{
    font-family:Siemens Progress, ui-sans-serif, system-ui, Arial;
    font-size:1.25rem;
    line-height:1.5rem
  }

  @media (min-width: 768px){
    .u-h2{
      font-size:1.5rem;
      line-height:1.875rem
    }
  }
}

.c-progressbar{
  display:flex;
  width:100%;
  margin-top:32px;
  margin-bottom:52px
}

.c-progressbar .c-progressbar__step{
  flex:1;
  position:relative;
  display:flex;
  justify-content:center;
  align-items:center
}

.c-progressbar .c-progressbar__step:not(:last-child) .c-progressbar__link::before,.c-progressbar .c-progressbar__step:not(:last-child) .c-progressbar__link::after{
  content:"";
  position:absolute;
  z-index:2;
  display:block;
  box-sizing:border-box;
  transition:all .15s ease-in-out
}

.c-progressbar .c-progressbar__step:not(:last-child) .c-progressbar__link::before{
  right:-20px;
  width:20px;
  height:44px;
  border-top:22px solid transparent;
  border-bottom:22px solid transparent;
  border-left:20px solid #000028
}

@media screen and (max-width: 640px){
  .c-progressbar .c-progressbar__step:not(:last-child) .c-progressbar__link::before{
    height:40px
  }
}

.c-progressbar .c-progressbar__step:not(:last-child) .c-progressbar__link::after{
  right:-18px;
  width:18px;
  border-top:20px solid transparent;
  border-bottom:20px solid transparent;
  border-left:18px solid #62627a
}

.c-progressbar .c-progressbar__step:not(:last-child) .c-progressbar__link:hover::after,.c-progressbar .c-progressbar__step:not(:last-child) .c-progressbar__link.router-link-active:after{
  border-left-color:#3cc8be
}

.c-progressbar .c-progressbar__step:not(:last-child) .c-progressbar__link.is-done:not(.router-link-active){
  background-color:#3cc8be
}

.c-progressbar .c-progressbar__step:not(:last-child) .c-progressbar__link.is-done:not(.router-link-active)::after{
  border-left-color:#3cc8be
}

.c-progressbar .c-progressbar__step:not(:last-child) .c-progressbar__link.is-done:not(.router-link-active):hover{
  background-color:#3cc8be
}

.c-progressbar .c-progressbar__step:not(:last-child) .c-progressbar__link.is-done:not(.router-link-active):hover::after{
  border-left-color:#3cc8be
}

.c-progressbar .c-progressbar__step:not(:last-child) .c-progressbar__link.router-link-active:before{
  height:calc(100% + 4px);
  border-top:26px solid transparent;
  border-bottom:26px solid transparent;
  border-left:20px solid #000028
}

.c-progressbar .c-progressbar__step:not(:last-child) .c-progressbar__link.router-link-active::after{
  transition:none !important;
  height:100%;
  border-top:24px solid transparent;
  border-bottom:24px solid transparent;
  border-left:18px solid #62627a
}

.c-progressbar .c-progressbar__step:not(:last-child) .c-progressbar__link.router-link-active:hover{
  transition:none !important
}

.c-progressbar .c-progressbar__step:not(:last-child) .c-progressbar__link.router-link-active:hover::after{
  border-left:18px solid #3cc8be
}

.c-progressbar .c-progressbar__link{
  font-size:0.875rem;
  line-height:1.25rem;
  width:100%;
  height:40px;
  display:flex;
  justify-content:center;
  align-items:center;
  padding:8px 0 8px 16px;
  background-color:#62627a;
  color:#fff;
  transition:all .15s ease-in-out !important
}

.c-progressbar .c-progressbar__link.router-link-active{
  padding:12px 0 12px 16px;
  height:48px
}

@media screen and (max-width: 640px){
  .c-progressbar .c-progressbar__link.router-link-active{
    height:44px
  }
}

.c-progressbar .c-progressbar__link.router-link-active{
  position:relative;
  background:linear-gradient(to right, #3CC8BE 0%, #3CC8BE calc(50% + 8px), #62627A calc(50% + 8px), #62627A 100%)
}

.c-progressbar .c-progressbar__link.router-link-active:hover{
  background:#3cc8be
}

.c-progressbar .c-progressbar__link.is-done{
  background:#3cc8be
}

.c-progressbar .c-progressbar__link.is-done .c-progressbar__checked{
  display:inline-block
}

.c-progressbar .c-progressbar__link.is-done::after{
  border-left:18px solid #3cc8be !important
}

.c-progressbar .c-progressbar__link.is-done .c-progressbar__icon{
  display:none
}

.c-progressbar .c-progressbar__link.is-done .c-progressbar__icon--kitchen{
  display:inline
}

.c-progressbar .c-progressbar__link:hover{
  background:#3cc8be
}

.c-progressbar .c-progressbar__checked{
  display:none
}

.c-progressbar .c-progressbar__step-desc{
  line-height:1.25
}

@media screen and (max-width: 1024px){
  .c-progressbar .c-progressbar__step-desc{
    display:none
  }

  .c-progressbar .c-progressbar__link:not(.router-link-active) .c-progressbar__icon{
    margin-right:8px
  }
}

@media screen and (max-width: 768px){
  .c-progressbar{
    margin-top:20px;
    margin-bottom:40px
  }

  .c-progressbar .c-progressbar__link{
    padding-left:20px
  }

  .c-progressbar .c-progressbar__link.router-link-active .c-progressbar__icon{
    margin-right:0
  }

  .c-progressbar__step-counter{
    margin-left:4px
  }
}

@media screen and (min-width: 768px)and (max-width: 1024px){
  .router-link-active .c-progressbar .c-progressbar__step-desc{
    display:block
  }

  .c-progressbar .c-progressbar__link:not(.router-link-active) .c-progressbar__icon{
    display:inline-block
  }
}

@media print{
  .no-print,.no-print *{
    display:none !important
  }
}